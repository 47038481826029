import {
  PaddingContainer,
  FlexContainer,
  Heading,
  ParaText,
  BlueText,
  IconContainer,
} from "../styles/Global.styled";
import { SkillsCard, SkillsCardContainers } from "../styles/MySkills.styled";
import { Skills } from "../utils/data";

import { motion } from "framer-motion";
import { fadeInLeftVarient, fadeInRightVarient } from "../utils/variants";

const MySkills = () => {
  return (
    <PaddingContainer
      id="Skills"
      top="7%"
      bottom="5%"
      responsiveLeft="1rem"
      responsiveRight="1rem"
      responsiveTop="5rem"
    >
      <FlexContainer
        responsiveFlex
        responsiveDirection="column-reverse"
        fullWidthChild
      >
        {/* Left Section */}
        <SkillsCardContainers
          as={motion.div}
          variants={fadeInLeftVarient}
          initial="hidden"
          whileInView="visible"
        >
          {Skills.map((skill) => (
            <SkillsCard>
              <IconContainer size="5rem" color="blue">
                {skill.icon}
              </IconContainer>
              <Heading as="h4" size="h4">
                {skill.tech}
              </Heading>
            </SkillsCard>
          ))}
        </SkillsCardContainers>

        {/* Right Section */}
        <motion.div
          variants={fadeInRightVarient}
          initial="hidden"
          whileInView="visible"
        >
          <Heading as="h4" size="h4">
            MY SKILLS
          </Heading>
          <Heading as="h2" size="h2" top="0.5rem">
            What <BlueText>I can do</BlueText>
          </Heading>
          <ParaText top="2rem" bottom="1.5rem">
            I thrive on turning ideas into reality through the power of code.
            With a strong foundation in JavaScript and a deep understanding of
            React JS, I bring innovation and efficiency to every project I
            undertake. My journey in the world of web development has equipped
            me with the skills to create seamless user experiences, ensuring
            that every line of code contributes to the overall success of the
            application.
          </ParaText>
          <ParaText>
            I have experience in developing and designing full stack web-based
            applications using JavaScript, React JS, Next JS, Node JS, Tailwind
            CSS, MongoDB, MySQL, and other technologies.
          </ParaText>
        </motion.div>
      </FlexContainer>
    </PaddingContainer>
  );
};
export default MySkills;
