import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import {
  PaddingContainer,
  FlexContainer,
  BlueText,
  Container,
} from "../styles/Global.styled";
import {
  NavbarContainer,
  Logo,
  MenuIcon,
  MenuItems,
} from "../styles/Navbar.styled";

import { GiHamburgerMenu } from "react-icons/gi";
import { Theme } from "../utils/theme";
import NavMenu from "./layouts/NavMenu";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      window.pageYOffset > 50 ? setSticky(true) : setSticky(false);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <NavbarContainer bgColor={sticky ? Theme.colors.primary : "transparent"}>
      <PaddingContainer
        top="1.2rem"
        bottom="1.2rem"
        responsiveLeft="1rem"
        responsiveRight="1rem"
      >
        <Container>
          <FlexContainer justify="space-between" responsiveFlex>
            {/* left logo */}
            <Logo as={motion.a} whileHover={{ scale: 1.2 }} href="#Home">
              Abhinav <BlueText>Umat</BlueText>
            </Logo>
            {/* right menu */}
            <MenuIcon
              as={motion.a}
              whileHover={{ scale: 1.2 }}
              onClick={() => setOpenMenu(true)}
            >
              <GiHamburgerMenu />
            </MenuIcon>
            <MenuItems>
              <Logo as={motion.a} whileHover={{ scale: 1.2 }} href="#Home">
                Home
              </Logo>
              <Logo as={motion.a} whileHover={{ scale: 1.2 }} href="#Skills">
                My Skills
              </Logo>
              <Logo as={motion.a} whileHover={{ scale: 1.2 }} href="#Projects">
                My Projects
              </Logo>
              <Logo as={motion.a} whileHover={{ scale: 1.2 }} href="#Contact">
                Contact
              </Logo>
            </MenuItems>
          </FlexContainer>
        </Container>
        <AnimatePresence>
          {openMenu && <NavMenu setOpenMenu={setOpenMenu} />}
        </AnimatePresence>
      </PaddingContainer>
    </NavbarContainer>
  );
};
export default Navbar;
