import {
  PaddingContainer,
  FlexContainer,
  Heading,
  ParaText,
  IconContainer,
  Button,
} from "../../styles/Global.styled";
import {
  TechStackCard,
  ProjectImageContainer,
  ProjectImage,
  GithubIcon,
} from "../../styles/MyProject.styled";

import { FaGithub } from "react-icons/fa";
import { motion } from "framer-motion";
import { Tilt } from "react-tilt";
import { fadeInLeftVarient, fadeInRightVarient } from "../../utils/variants";

const Project = ({ data }) => {
  return (
    <FlexContainer
      direction={data.reverse ? "row-reverse" : false}
      fullWidthChild
    >
      {/* Left Section */}
      <motion.div
        variants={data.reverse ? fadeInRightVarient : fadeInLeftVarient}
        initial="hidden"
        whileInView="visible"
      >
        <FlexContainer align="center" gap="1rem">
          <Heading as="h3" size="h3" bottom="1rem">
            {data.project_name}
          </Heading>
          <IconContainer color="blue" size="2rem">
            <GithubIcon target="_blank" href={data.github_url}>
              <FaGithub />
            </GithubIcon>
          </IconContainer>
        </FlexContainer>
        <PaddingContainer top="1rem">
          <FlexContainer gap="1.5rem">
            {data.tech_stack.map((tech) => (
              <TechStackCard>{tech}</TechStackCard>
            ))}
          </FlexContainer>
        </PaddingContainer>
        <ParaText top="1.5rem" bottom="2rem">
          {data.project_description}
        </ParaText>
        <FlexContainer responsiveJustify responsiveFlex>
          <Button target="_blank" href={data.project_url}>
            Visit Website
          </Button>
        </FlexContainer>
      </motion.div>

      {/* Right section */}
      <Tilt
        options={{
          max: 45,
          scale: 1,
          speed: 450,
        }}
      >
        <ProjectImageContainer
          justify={data.reverse ? "flex-start" : "flex-end"}
          as={motion.div}
          variants={data.reverse ? fadeInLeftVarient : fadeInRightVarient}
          initial="hidden"
          whileInView="visible"
        >
          <ProjectImage src={data.project_img} alt={data.project_name} />
        </ProjectImageContainer>
      </Tilt>
    </FlexContainer>
  );
};
export default Project;
