import styled from "styled-components";

export const SkillsCardContainers = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 2rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 10%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: auto auto;
    grid-gap: 1rem;
    padding: 0;
  }
`;

export const SkillsCard = styled.div`
  width: 200px;
  border: 1px solid #fff;
  padding: 3rem 0;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary_light};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    // width: 100%;
    width: 160px;
    margin-top: 2rem;
  }
`;
