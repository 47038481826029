import React from "react";
import { motion } from "framer-motion";
import { Tilt } from "react-tilt";

import {
  PaddingContainer,
  FlexContainer,
  Heading,
  ParaText,
  BlueText,
  Button,
} from "../styles/Global.styled";
import {
  ShowcaseParticleContainer,
  ShowcaseImageCard,
  Particle,
} from "../styles/Showcase.styled";
import ShowcaseImg from "../assets/profile.png";
import BackgroundImg from "../assets/particle.png";
import Resume from "../assets/Resume_Abhinav Umat.pdf";

import { fadeInLeftVarient, fadeInRightVarient } from "../utils/variants";

const Showcase = () => {
  return (
    <PaddingContainer
      id="Home"
      left="3%"
      right="10%"
      top="15%"
      bottom="10%"
      responsiveLeft="1rem"
      responsiveRight="1rem"
      responsiveTop="8rem"
    >
      <FlexContainer align="center" fullWidthChild>
        {/* Left Content */}
        <motion.div
          variants={fadeInLeftVarient}
          initial="hidden"
          whileInView="visible"
          align="left"
        >
          <Heading as="h4" size="h4">
            Hello there!
          </Heading>
          <Heading as="h2" size="h2" top="0.5rem" bottom="1rem">
            I'm <BlueText>Abhinav Umat</BlueText>
          </Heading>
          <Heading as="h3" size="h3">
            I'm a <BlueText>Frontend Developer</BlueText>
          </Heading>
          <ParaText as="p" top="2rem" bottom="4rem">
            A Frontend Developer with a master's degree in computer science and
            expertise in full stack web development, I am a passionate developer
            with a knack for crafting dynamic and responsive web applications.
          </ParaText>
          <FlexContainer responsiveJustify responsiveFlex>
            <Button target="_blank" href={Resume}>
              Download Resume
            </Button>
          </FlexContainer>
        </motion.div>

        {/* Right Content */}
        <FlexContainer
          as={motion.div}
          variants={fadeInRightVarient}
          initial="hidden"
          whileInView="visible"
          justify="flex-end"
          top="10%"
        >
          <ShowcaseParticleContainer>
            <Tilt
              options={{
                max: 45,
                scale: 1,
                speed: 450,
              }}
            >
              <ShowcaseImageCard>
                <img src={ShowcaseImg} alt="showcase" />
              </ShowcaseImageCard>
            </Tilt>
            <Particle
              as={motion.img}
              animate={{
                x: [0, 100, 0],
                rotate: 360,
                scale: [1, 0.5, 1],
              }}
              transition={{
                duration: 20,
                repeat: Infinity,
              }}
              src={BackgroundImg}
              alt="particle"
              top="-80px"
              left="20px"
              rotate="60deg"
            />
            <Particle
              as={motion.img}
              animate={{
                y: [0, 100, 0],
                rotate: 360,
                scale: [1, 0.8, 1],
              }}
              transition={{
                duration: 18,
                repeat: Infinity,
              }}
              src={BackgroundImg}
              alt="particle"
              top="50px"
              right="-70px"
              rotate="0deg"
            />

            <Particle
              as={motion.img}
              animate={{
                y: [0, -100, 0],
                rotate: 360,
                scale: [1, 0.9, 1],
              }}
              transition={{
                duration: 15,
                repeat: Infinity,
              }}
              src={BackgroundImg}
              alt="particle"
              bottom="10px"
              left="-70px"
              rotate="50deg"
            />
          </ShowcaseParticleContainer>
        </FlexContainer>
      </FlexContainer>
    </PaddingContainer>
  );
};
export default Showcase;
