import { PaddingContainer, Heading, BlueText } from "../styles/Global.styled";

import { Projects } from "../utils/data";
import Project from "./layouts/Project";
import { motion } from "framer-motion";
import { fadeInTopVarient } from "../utils/variants";

const MyProjects = () => {
  return (
    <PaddingContainer
      id="Projects"
      top="8%"
      bottom="5%"
      responsiveLeft="1rem"
      responsiveRight="1rem"
      responsiveTop="5rem"
    >
      <Heading
        as={motion.h4}
        variants={fadeInTopVarient}
        initial="hidden"
        whileInView="visible"
        size="h4"
      >
        MY PROJETCS
      </Heading>
      <Heading
        as={motion.h4}
        variants={fadeInTopVarient}
        initial="hidden"
        whileInView="visible"
        size="h2"
      >
        What <BlueText>I have built</BlueText>
      </Heading>
      {Projects.map((project) => (
        <PaddingContainer
          key={project.id}
          top="5rem"
          responsiveTop="1rem"
          bottom="5rem"
        >
          <Project data={project} />
        </PaddingContainer>
      ))}
    </PaddingContainer>
  );
};
export default MyProjects;
