import {
  PaddingContainer,
  FlexContainer,
  Heading,
  BlueText,
} from "../styles/Global.styled";
import {
  ContactForm,
  FormLabel,
  FormInput,
  FormButton,
} from "../styles/Footer.styled";

import { motion } from "framer-motion";
import { fadeInBottomVarient } from "../utils/variants";

import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";

const Footer = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_pyxppdz",
        "template_4tsk7jh",
        {
          from_name: form.name,
          to_name: "Abhinav Umat",
          from_email: form.email,
          to_email: "abhinav.umat1995@gmail.com",
          message: form.message,
        },
        "ibBVVf7kfl4GJQEO0"
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible.");

          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };

  return (
    <PaddingContainer
      id="Contact"
      top="5%"
      bottom="10%"
      responsiveTop="5rem"
      responsiveBottom="10rem"
    >
      <Heading
        as={motion.h4}
        variants={fadeInBottomVarient}
        initial="hidden"
        whileInView="visible"
        size="h4"
        align="center"
      >
        MY CONTACT
      </Heading>
      <Heading
        as={motion.h2}
        variants={fadeInBottomVarient}
        initial="hidden"
        whileInView="visible"
        size="h2"
        align="center"
        top="0.5rem"
      >
        Contact <BlueText>Me Here</BlueText>
      </Heading>
      <PaddingContainer top="3rem">
        <FlexContainer justify="center">
          <ContactForm
            as={motion.form}
            variants={fadeInBottomVarient}
            initial="hidden"
            whileInView="visible"
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <PaddingContainer bottom="2rem">
              <FormLabel>Name:</FormLabel>
              <FormInput
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
                placeholder="Enter your Name"
                required
              />
            </PaddingContainer>
            <PaddingContainer bottom="2rem">
              <FormLabel>Email:</FormLabel>
              <FormInput
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                placeholder="Enter your Email"
                required
              />
            </PaddingContainer>
            <PaddingContainer bottom="2rem">
              <FormLabel>Message:</FormLabel>
              <FormInput
                as="textarea"
                rows={4}
                name="message"
                value={form.message}
                onChange={handleChange}
                placeholder="Enter your Message"
                required
              />
            </PaddingContainer>
            <FlexContainer justify="center" responsiveFlex>
              <FormButton type="submit">
                {loading ? "Sending..." : "Send Message"}
              </FormButton>
            </FlexContainer>
          </ContactForm>
        </FlexContainer>
      </PaddingContainer>
    </PaddingContainer>
  );
};
export default Footer;
