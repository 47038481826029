import styled from "styled-components";

export const ContactForm = styled.form`
  width: 40%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 90%;
    margin: auto;
  }
`;

export const FormLabel = styled.p`
  color: ${({ theme }) => theme.colors.para_text_color};
  font-size: 1.2rem;
  padding-bottom: 10px;
`;

export const FormInput = styled.input`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary_light};
  border: 1px solid ${({ theme }) => theme.colors.para_text_color};
  color: ${({ theme }) => theme.colors.para_text_color};
  border-radius: 5px;
  padding: 15px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.para_text_color};
  }
`;

export const FormButton = styled.button`
  display: inline-block;
  width: max-content;
  padding: 1rem 2rem;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary_light};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  z-index: 1;
  box-shadow: 0 0.3rem ${({ theme }) => theme.colors.primary};

  &:hover {
    // color: ${({ theme }) => theme.colors.primary_light};
    // background-color: ${({ theme }) => theme.colors.white};
    filter: brightness(150%);
  }

  &:active {
    transform: translate(0, 0.5rem);
    box-shadow: 0 0.3rem rgba(255, 255, 255, 0.65);
  }
`;
