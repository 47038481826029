import { FaReact, FaNode, FaCss3Alt } from "react-icons/fa";
import { SiJavascript, SiTailwindcss } from "react-icons/si";
import { TbBrandNextjs } from "react-icons/tb";
import { DiMongodb } from "react-icons/di";
import { FaDocker } from "react-icons/fa6";

import Genius from "../assets/projects/1.png";
import Exercise from "../assets/projects/2.png";
import Joke from "../assets/projects/3.png";
import Quote from "../assets/projects/4.png";

export const Skills = [
  {
    id: 0,
    tech: "JavaScript",
    icon: <SiJavascript />,
  },
  {
    id: 1,
    tech: "React JS",
    icon: <FaReact />,
  },
  {
    id: 2,
    tech: "Node JS",
    icon: <FaNode />,
  },
  {
    id: 3,
    tech: "Next JS",
    icon: <TbBrandNextjs />,
  },
  {
    id: 4,
    tech: "Tailwind CSS",
    icon: <SiTailwindcss />,
  },
  {
    id: 5,
    tech: "Mongo DB",
    icon: <DiMongodb />,
  },
];

export const Projects = [
  // {
  //   id: 0,
  //   project_name: "AI SaaS",
  //   project_description:
  //     "An AI SaaS Application that leverages cutting-edge artificial intelligence (AI) technologies to empower users in generating diverse forms of content seamlessly. It covers a spectrum of creative domains, including Image Generation, Code Generation, Music Generation, Video Generation, and Conversation.",
  //   tech_stack: ["Next JS", "Tailwind CSS", "TypeScript", "Clerk"],
  //   project_img: Genius,
  //   project_url: "https://genius-sooty-ten.vercel.app",
  //   github_url: "https://github.com/Abhinavumat95/Genius",
  //   reverse: false,
  // },
  {
    id: 1,
    project_name: "Gym Exercises",
    project_description:
      "A comprehensive gym exercises website designed to inspire and support individuals on their fitness journey. Users can explore various exercises for different body parts. It aims to be the go-to resource for individuals seeking a healthier and more active lifestyle.",
    tech_stack: ["React JS", "Tailwind CSS", "RESTful APIs"],
    project_img: Exercise,
    project_url: "https://jovial-tarsier-aa8660.netlify.app",
    github_url: "https://github.com/Abhinavumat95/Gym-Exercises",
    reverse: false,
  },
  {
    id: 2,
    project_name: "Joke Teller",
    project_description:
      "A delightful and user-friendly joke teller website designed to bring joy and laughter to users' lives. It aims to be the go-to destination for anyone in need of a good laugh, offering a diverse range of jokes across different categories and styles.",
    tech_stack: ["HTML", "CSS", "JavaScript"],
    project_img: Joke,
    project_url: "https://fastidious-sorbet-48cdf8.netlify.app",
    github_url: "https://github.com/Abhinavumat95/Joke-Teller",
    reverse: true,
  },
  {
    id: 3,
    project_name: "Quote Generator",
    project_description:
      "A user-friendly website dedicated to delivering daily inspiration and motivation through a curated collection of quotes. It is designed to be a go-to destination for individuals seeking a daily dose of wisdom, encouragement, and thought-provoking insights from a diverse range of authors, thinkers, and influencers.",
    tech_stack: ["HTML", "CSS", "JavaScript"],
    project_img: Quote,
    project_url: "https://voluble-sprite-92e483.netlify.app",
    github_url: "https://github.com/Abhinavumat95/Quote-Generator",
    reverse: false,
  },
];

export const navLinks = [
  {
    id: 0,
    name: "Home",
    href: "Home",
  },
  {
    id: 1,
    name: "My Skills",
    href: "Skills",
  },
  {
    id: 2,
    name: "My Projects",
    href: "Projects",
  },
  {
    id: 3,
    name: "My Contact",
    href: "Contact",
  },
];
